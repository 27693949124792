@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
/*@font-face {
    font-family: Garamond;
    src: url(../fonts/Garamond.eot);
    src: url(../fonts/Garamond.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Garamond.woff) format("woff"),
    url(../fonts/Garamond.ttf) format("truetype"),
    url(../fonts/Garamond.svg#aller) format("svg");
    font-weight: 400;
    font-style: normal
}*/

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

body {
    font: 100% Quicksand, sans-serif;
    font-size: calc(13px + .3vw);
    color: #010101;
    background-color: #fff1e5;
    background-image: url(http://mashrur.co.uk/img/background-overlay-final.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

#react-root {
    margin: 5% 0
}

.img-responsive {
    width: 100%
}

.img-circle {
    border-radius: 50%
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-weight: 700;
    line-height: 1.1
}

p {
    margin: 0 0 22px;
    line-height: 1.875em
}

.text-muted {
    opacity: .65;
    font-size: 1.143em
}

.profile-card-container aside {
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2), inset 0 -3px 0 rgba(0, 0, 0, .22);
    width: 100%
}

.profile-card-container aside .profile-card {
    background: #c0e3e7;
    display: flex;
    flex-flow: column wrap;
    padding: 30px 20px 0 20px;
    align-items: center;
    width: 100%;
    height: 100%
}

.profile-card-container aside .profile-card img {
    margin-bottom: 20px
}

.profile-card-container aside .profile-card h2 {
    text-align: center;
    font-size: 1.625em;
    margin-bottom: .688em
}

ul.social-links {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap
}

ul.social-links li {
    margin: 0 7px
}

ul.social-links li a {
    color: #010101
}

ul.social-links li a i {
    font-size: 1.2em
}

main .box {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 40px 20px;
    margin-bottom: 50px
}

.download {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 20px 30px 20px
}

.download button.button-fill {
    background-color: #010101;
    border-radius: 5em;
    color: #fff;
    padding: 5px 15px
}

.profile h1 {
    margin-bottom: 50px;
    font-size: 30px
}

.pro-skills h2 {
    margin-bottom: 30px
}

.pro-skills .skills {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around
}

.pro-skills .skills .skill {
    width: 40%;
    margin-bottom: 20px
}

.pro-skills .skills .skill h4 {
    font-size: .85em;
    line-height: 1.2em;
    display: block;
    margin-bottom: .7em
}

.pro-skills .skills .skill img {
    margin-bottom: 10px
}

.pro-skills .skills .skill .progress {
    height: 5px
}

.pro-skills .skills .skill .progress .progress-bar {
    background-color: #c0e3e7
}

.portfolio h1 {
    margin-bottom: 30px
}

.portfolio .articles {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between
}

.portfolio .articles article {
    overflow: hidden;
    width: calc(100%/2 - 20px);
    margin-bottom: 20px
}

.portfolio .articles article img {
    width: 100%
}

.portfolio .articles article.half {
    width: 48%
}

.portfolio .articles article.full {
    width: 100%
}

.hire a {
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    padding: 8px 25px;
    display: inline-block;
    border-radius: 35px;
    border: 2px solid #c0e3e7;
    cursor: pointer;
    box-shadow: transparent 0 0 0;
    -webkit-transition: all .5s cubic-bezier(.23, 1, .32, 1) 0s;
    transition: all .5s cubic-bezier(.23, 1, .32, 1) 0s;
    color: #010101;
    font-weight: bolder
}

.workExp h1 {
    margin-bottom: 50px;
    font-size: 30px
}

.time-line {
    position: relative;
    margin-left: 210px
}

.time-line:before {
    background-color: #c0e3e7;
    top: 10px;
    left: 0;
    bottom: 0;
    width: 2px;
    content: '';
    opacity: .5;
    position: absolute
}

.time-line .tl-item {
    position: relative;
    padding-right: 90px;
    padding-left: 20px;
    margin-bottom: 45px
}

.time-line .tl-item:before {
    background-color: #010101;
    content: "";
    top: 10px;
    left: -1px;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%
}

.time-line .tl-item span.tl-date {
    background-color: #c0e3e7;
    font-size: .875em;
    text-align: center;
    letter-spacing: 0;
    line-height: 1.1;
    color: #010101;
    margin-left: 0;
    margin-right: 22px;
    padding: 8px 6px;
    position: absolute;
    width: 160px;
    right: 100%;
    left: auto;
    top: -4px
}

.time-line .tl-item span.tl-date::after {
    position: absolute;
    border-color: #c0e3e7;
    content: "";
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 16px solid transparent!important;
    border-bottom: 16px solid transparent!important;
    border-right: 10px solid transparent!important;
    border-left: 10px solid #c0e3e7
}

.time-line .tl-item h3 {
    font-size: 20px;
    margin-bottom: 22px;
    margin-top: 0
}

.time-line .tl-item div.tl-inst {
    margin: 20px 0
}

.time-line .tl-item p {
    font-size: .87em
}

.education h1 {
    margin-bottom: 50px;
    font-size: 30px
}

footer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px
}

footer span {
    font-size: 1em
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

